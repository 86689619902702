import React, { Component, useState, useEffect } from 'react'

const ContentExample = () => {
  const [avviso1, setavviso1] = useState(false);
  const [avviso2, setavviso2] = useState(false);
  const [avviso3, setavviso3] = useState(false);

  const current = new Date();
  const davviso1 = new Date('2023-02-18 09:00');
  const davviso2 = new Date('2023-02-17 09:00');
  const davviso3 = new Date('2023-03-18 12:00');


  useEffect(() => {
    if (current > davviso1) {
      setavviso1(true);
    }
    if (current > davviso3) {
      setavviso3(true);
    }

  }, [])

  return (
    <>

      <main>
        <div className="container my-4">

          <section id="intro">
            <div className="container">
              <div className="row">
                <div className="offset-lg-1 col-lg-6 col-md-8">
                  <div className="titolo-sezione">
                    <h2>Servizi sociali</h2>
                    <p>L'Area si occupa del sostegno alle persone in stato di fragilità, di bisogno socio-economico o a rischio di emarginazione.</p>
                  </div>
                </div>
                <div className="offset-lg-1 col-lg-3 col-md-4">
                  <aside id="argomenti-sezione">
                    <div className="argomenti">

                      <h4>Argomenti</h4>
                      <div className="argomenti-sezione-elenco">
                        <a href="#" title="Vai all'argomento: Integrazione sociale" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">Integrazione sociale</a>
                        <a href="#" title="Vai all'argomento: Abitazione" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">Abitazione</a>
                        <a href="#" title="Vai all'argomento: Famiglia" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">Famiglia</a>
                        <a href="#" title="Vai all'argomento: Cultura" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">Cultura</a>
                        <a href="#" title="Vai all'argomento: Istruzione" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">Istruzione</a>
                        <a href="#" title="Vai all'argomento: Studente" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">Studente</a>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="row" style={{ height: 20 }}>
            </div>
          </section>
          <section id="articolo-dettaglio-testo">
            <div className="row">
              <div className="col-12 col-lg-12">
                {/*start card*/}
                <div className="card-wrapper card-space">
                  <div className="card card-bg">
                    <div className="card-body">


                      <div className="articolo-paragrafi">
                      <div className="row">
                      <img className="logo" width="100%" src="https://servizisociali.comune.rende.cs.it/logopon.png" style={{ margin: "0px auto;" }} />
                        </div>
                        <div className="row">

                          <div className="">
                            <a id="articolo-par-competenze"> </a>
                            <h4>Cosa fa</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="">
                            <p>Il Servizio Sociale Professionale si occupa di garantire sul territorio una rete di servizi per rispondere alle situazioni di bisogno e difficoltà che la persona può&nbsp;incontrare nel corso della propria vita.<br />
                              Assicura un servizio di ascolto, di consulenza e di orientamento per l'accesso al sistema dei servizi alla persona, anche attraverso l’individuazione di risposte idonee a prevenire e a ridurre le situazioni di fragilità e di disagio sociale, per favorire l’autonomia della persona, sostenere la famiglia, tutelare le persone più vulnerabili.<br />
                              Gli interventi di sostegno sono rivolti ai minori, ai singoli e alle famiglie in difficoltà, alle persone con disabilità o con problemi di salute fisica o mentale, agli anziani, nonché a persone senza fissa dimora, a donne vittime di violenza, a minori stranieri non accompagnati, a persone che necessitino di una tutela o sostegno a causa di condizioni di particolare fragilità e bisogno.<br />
                              In campo amministrativo gestisce tutte le procedure e gli adempimenti necessari all’organizzazione ed erogazione delle prestazioni alle persone ammesse alla fruizione dei servizi.<br />
                              Inoltre, si occupa di promuovere, attraverso il Piano locale unitario dei servizi (Plus), la programmazione e la progettazione del sistema integrato dei servizi sociali e sociosanitari.
                            </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-md-11 paragrafo">
              <a id="articolo-par-servizi"> </a>
              <h3>AVVISI</h3>
            </div>
          </div>

          <section>
            <div className="row">
              {/**   <div className="col-12 col-lg-4">
                  <div className="card-wrapper card-space">
                    <div className="card card-bg card-big no-after">
                      <div className="card-body">
                        <div className="head-tags">
                          <a className="card-tag" href="#">buoni spesa</a>
                          <span className="data">10/10/2022</span>
                        </div>
                        <h3 className="card-title h5 ">Presentazione domanda</h3>
                        <p className="card-text">Form per l'invio della domanda per la richiesta dei buoni spesa 2023</p>
                        <div className="it-card-footer">
                          <span className="card-signature">di Daniele Carnovale</span>
                          <a className="btn btn-outline-primary btn-sm" href="/domanda">Invia Domanda</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                 
                  <div className="card-wrapper card-space">
                    <div className="card card-bg card-big no-after">
                      <div className="card-body">
                        <div className="head-tags">
                          <a className="card-tag" href="#">buoni spesa</a>
                          <span className="data">10/10/2022</span>
                        </div>
                        <h3 className="card-title h5 ">Selezione Fornitori </h3>
                        <p className="card-text">Form per Selezione fornitori buoni spesa 2023</p>
                        <div className="it-card-footer">
                          <span className="card-signature">di Daniele Carnovale</span>
                          <a className="btn btn-outline-primary btn-sm" href="/domandafornitori">Fornitori</a>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  
                </div> */}

              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >LONG LIST</p>
                        <span className="data">06/09/2023</span>
                      </div>

                      <p className="card-text">
                        LONG LIST<br />
                        AVVISO PUBBLICO PER LA COSTITUZIONE DI UNA LONG LIST DI ESPERTI PER IL CONFERIMENTO DI INCARICHI PROFESSIONALI ESTERNI PER L’IMPLEMENTAZIONE DI INTERVENTI DI SEGRETARIATO SOCIALE DEL SERVIZIO SOCIALE PROFESSIONALE DEI COMUNI AFFERENTI ALL’AMBITO TERRITORIALE SOCIALE N. 2 DI RENDE.
                      </p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>

                        <a className="btn btn-outline-primary btn-sm" target="_blank" href="https://hosting.soluzionipa.it/rende/albo/albo_dettagli.php?id=1304&CSRF=38479ec241d3514c89596eb996d402f4">Avviso </a>

                        <a style={{ border: 'solid red' }} className="btn btn-outline-primary btn-sm" href="#">Scaduto </a>
                        {/**    
                          <a className="btn btn-outline-primary btn-sm" href="https://servizisociali.comune.rende.cs.it/fna">Vai alla Domanda </a>
                      */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-12 col-lg-4">

                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >Gestori Centri Estivi</p>
                        <span className="data">12/07/2023</span>
                      </div>

                      <p className="card-text">
                        MODULO DOMANDA GESTORI <br />

                      </p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>

                        <a className="btn btn-outline-primary btn-sm" target="_blank" href="https://www.comune.rende.cs.it/files/upload/PortaleNews/allegati/avviso%20per%20la%20costituzione%20di%20un%20registro%20dei%20centri%20estivi%20accreditati.pdf">Avviso </a>
                        {/**   <a className="btn btn-outline-primary btn-sm" href="https://servizisociali.comune.rende.cs.it/gestoriestivi">Vai alla Domanda </a>
                       */} <a style={{ border: 'solid red' }} className="btn btn-outline-primary btn-sm" href="#">Scaduto </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >Centri Estivi</p>
                        <span className="data">12/07/2023</span>
                      </div>

                      <p className="card-text">

                        DOMANDA PER LA RICHIESTA DI CONTRIBUTO ECONOMICO PER LA FREQUENZA DI CENTRI E/O SERVIZI ESTIVI SOCIO-EDUCATIVI E RICREATIVI - ESTATE 2023.

                      </p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>

                        <a className="btn btn-outline-primary btn-sm" target="_blank" href="https://www.comune.rende.cs.it/files/upload/PortaleNews/allegati/avviso%20centri%20estivi%202023%20per%20le%20famiglie.pdf">Avviso </a>
                        <a className="btn btn-outline-primary btn-sm" target="_blank" href="https://hosting.soluzionipa.it/rende/portal/getDoc.php?f=documenti/984_doc14536220230911123611_Marcato_1694427980.pdf&CSRF=cb68f55efbd3b46d048854d1698f23aa">Avviso Ricevute</a>
                        {/*  
                      <a className="btn btn-outline-primary btn-sm" href="https://servizisociali.comune.rende.cs.it/domandacentriestivi23">Vai alla Domanda </a>
                      */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >FNA 2015</p>
                        <span className="data">12/07/2023</span>
                      </div>

                      <p className="card-text">
                        FNA 2015<br />
                        AVVISO PUBBLICO PER L’EROGAZIONE DEL SERVIZIO DI ASSISTENZA DOMICILIARE NON SPECIALISTICA DI PERSONE NON AUTOSUFFICIENTI A VALERE SUI FONDI DELLA DGR 638 del 14.12.2018;
                      </p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>

                        <a style={{ border: 'solid red' }} className="btn btn-outline-primary btn-sm" href="#">Scaduto </a>
                        {/**    
                          <a className="btn btn-outline-primary btn-sm" href="https://servizisociali.comune.rende.cs.it/fna">Vai alla Domanda </a>
                      */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >Scuola</p>
                        <span className="data">30/05/2023</span>
                      </div>

                      <p className="card-text">
                        Servizio di Refezione Scolastica<br />
                        Anno Scolastico 2024/2025

                      </p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>

                        <a className="btn btn-outline-primary btn-sm" href="https://www.comune.rende.cs.it/files/ufficio-scuole/avviso-refezione-scolastica-2024-2025.pdf">Avviso </a>

                        <a className="btn btn-outline-primary btn-sm" href="https://servizisociali.comune.rende.cs.it/refezione">Vai alla Domanda </a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >Scuola, Trasporti</p>
                        <span className="data">16/04/2024</span>
                      </div>

                      <p className="card-text">
                        Servizio di Trasporto Scolastico<br />
                        Anno Scolastico 2024/2025

                      </p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>

                        <a className="btn btn-outline-primary btn-sm" href="https://www.comune.rende.cs.it/files/ufficio-scuole/avviso-trasporto-scolastico-2024-2025.pdf">Avviso </a>

                        <a className="btn btn-outline-primary btn-sm" href="https://servizisociali.comune.rende.cs.it/trasporto">Vai alla Domanda </a>
                   
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >Catalogo Servizi</p>
                        <span className="data">20/03/2023</span>
                      </div>

                      <p className="card-text">Manifestazione di interesse finalizzata alla costituzione di un Catalogo di prestatori di servizi socio-educativi e di sostegno educativo extrascolastico in favore di minori, appartenenti a nuclei familiari beneficiari di misure di sostegno al reddito e/o a rischio di emarginazione sociale, presi in carico dal servizio sociale dei comuni dell'ambito territoriale n. 2 di Rende comprendente i comuni di Castiglione Cosentino, Castrolibero, Marano Marchesato, Marano Principato, Rende, Rose, San Fili, San Pietro in Guarano e San Vincenzo la Costa</p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>
                        <a className="btn btn-outline-primary btn-sm" href="/interesseaccrediti" > Vai alla Domanda</a>


                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >STRATEGIA URBANA COSENZA - RENDE</p>
                        <span className="data">27/03/2023</span>
                      </div>

                      <p className="card-text">Avviso pubblico

                        per la concessione di buoni servizio per i servizi

                        socioeducativi della prima infanzia</p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>
                        {/*<a className="btn btn-outline-primary btn-sm" href="/agendaurbana"  > Vai alla Domanda</a>
                      */}
                        <a style={{ border: 'solid red' }} className="btn btn-outline-primary btn-sm" href="#">Scaduto </a>


                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >Rette Strutture </p>
                        <span className="data">25/05/2023</span>
                      </div>

                      <p className="card-text">
                        Presa in carico richiesta di ammissione integrazione retta anno 2024.

                      </p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>


                      <a className="btn btn-outline-primary btn-sm" target='_blank' href="https://hosting.soluzionipa.it/rende/albo/albo_dettagli.php?id=3938&CSRF=9b81457a31366a6b7c904d4057c19b6b"  >Avviso</a>
                        <a className="btn btn-outline-primary btn-sm" href="/strutture"  > Vai alla Domanda</a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >Operatori economici</p>
                        <span className="data">28/03/2023</span>
                      </div>

                      <p className="card-text">

                        Manifestazione di Interesse per l’iscrizione all’Elenco aperto di Operatori Economici per l’erogazione di prestazioni da parte di Operatori Socio Sanitari (OSS) ed Operatori Socio Assistenziali (OSA) in favore dei beneficiari del bando FNA 2015</p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>
                        <a className="btn btn-outline-primary btn-sm" href="/elencooperatori"  > Vai alla Domanda</a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >Rimborso Famiglie</p>
                        <span className="data">09/02/2023</span>
                      </div>

                      <p className="card-text">Riconoscimento di un contributo a rimborso totale o parziale delle spese sostenute dalle famiglie per la frequenza del/della proprio/a figlio/a minore (da 3 a 17 anni), residente nel Comune di Rende, di attività presso centri estivi, servizi socio/educativi territoriali, centri con funzione educativa e ricreativa, comprese quelle finalizzate alla promozione dello studio delle materie STEM, relativamente al periodo 01 giugno – 31 dicembre 2022.</p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>
                        <a style={{ border: 'solid red' }} className="btn btn-outline-primary btn-sm" href="#">Scaduto </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >FNA</p>
                        <span className="data">17/02/2023</span>
                      </div>

                      <p className="card-text">RICHIESTA EROGAZIONE DEL SERVIZIO DI ASSISTENZA DOMICILIARE NON SPECIALISTICA DI PERSONE NON AUTOSUFFICIENTI DI ETÀ INFERIORE A 65 ANNI A VALERE SUI FONDI DELLA DGR 464 del 12/11/2015 ANNUALITA’ 2014.</p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>
                        {avviso1 && <a style={{ border: 'solid red' }} className="btn btn-outline-primary btn-sm" href="/#">scaduto</a>

                        }
                        {!avviso1 && <a className="btn btn-outline-primary btn-sm" href="/">Invio domanda attivo dalle ore 9:00 del 18/02/2023</a>

                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="card-wrapper card-space">
                  <div className="card card-bg card-big no-after">
                    <div className="card-body">
                      <div className="head-tags">
                        <p className="card-tag" >Voucher Mensa Scolastica</p>
                        <span className="data">18/03/2023</span>
                      </div>

                      <p className="card-text">VOUCHER PER IL SERVIZIO DI REFEZIONE SCOLASTICA DELLLE SCUOLE DELL'INFANZIA PUBBLICHE— ANNO SCOLASTICO 2021- 2022</p>
                      <div className="it-card-footer">
                        <span className="card-signature"></span>
                        {avviso3 &&
                          <a style={{ border: 'solid red' }} className="btn btn-outline-primary btn-sm" href="#">Scaduto </a>

                        }
                        {!avviso3 && <a className="btn btn-outline-primary btn-sm" href="/">Invio domanda attivo dalle ore 12:00 del 18/03/2023</a>

                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {avviso2 &&
                <div className="col-12 col-lg-4">
                  <div className="card-wrapper card-space">
                    <div className="card card-bg card-big no-after">
                      <div className="card-body">
                        <div className="head-tags">
                          <p className="card-tag" >Elenco Operatori</p>
                          <span className="data">16/02/2023</span>
                        </div>

                        <p className="card-text">Domanda per l’iscrizione all’Elenco aperto di Operatori Economici per l’erogazione di
                          prestazioni da parte di Operatori Socio Sanitari (OSS) ed Operatori Socio Assistenziali (OSA)
                          in favore dei beneficiari del bando FNA 2015</p>
                        <div className="it-card-footer">
                          <span className="card-signature"></span>
                          <a className="btn btn-outline-primary btn-sm" target='_blank' href="/elencooperatori">Apri</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {/*          <div className="col-12 col-lg-4">
                 <div className="card-wrapper card-space">
                   <div className="card card-bg card-big no-after">
                     <div className="card-body">
                       <div className="head-tags">
                         <p className="card-tag" >Selezione fornitori</p>
                         <span className="data">23/12/2022</span>
                       </div>
                       <h3 className="card-title h5 ">Manifestazione d'interesse </h3>
                       <p className="card-text">ISCRIZIONE IN UN ALBO DISTRETTUALE DI PRESTATORI ACCREDITATI PER L’EROGAZIONE DI SERVIZI SOCIO-EDUCATIVI</p>
                       <div className="it-card-footer">
                         <span className="card-signature"></span>
                         <a className="btn btn-outline-primary btn-sm" href="/albofornitori">Apri</a>
                       </div>
                     </div>
                   </div>
                 </div>                 
               </div>

               */}
            </div>
          </section>
        </div>
      </main >

      <a href="#" aria-hidden="true" data-attribute="back-to-top" className="back-to-top">
        <svg className="icon icon-light">
          <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-up"></use>
        </svg>
      </a>


    </>


  )



}
export default ContentExample;
